<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
@import url("common/font/font.css");
@import url("common/common.less");
* {
  margin: 0;
  padding: 0;
  /* width: 100%; */
  height: 100%;
}

</style>
